import type { ActivatedRoute, Router } from '@angular/router';

import type { ApolloQueryResult } from '@apollo/client/core';
import type { DefaultDataTableSource } from '@devop/ngx-metronic/data-table';
import type { QueryRef } from 'apollo-angular';
import { DateTime } from 'luxon';

import {
  catchError,
  EMPTY,
  filter,
  merge,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
export * from '@devop/ngx-utils';
export const MONTHS = [
  'Sausis',
  'Vasaris',
  'Kovas',
  'Balandis',
  'Gegužė',
  'Birželis',
  'Liepa',
  'Rugpjūtis',
  'Rugsėjis',
  'Spalis',
  'Lapkritis',
  'Gruodis',
];
export const MONTHS_SHORT = [
  'Saus',
  'Vas',
  'Kov',
  'Bal',
  'Geg',
  'Bir',
  'Lie',
  'Rgp',
  'Rgs',
  'Spa',
  'Lap',
  'Gru',
];

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const now = DateTime.now();

const cssVariableCache: any = {};

export enum BSBreakpoints {
  XS = '(max-width: 576px)',
  SM = '(min-width: 576px)',
  MD = '(min-width: 768px)',
  LG = '(min-width: 992px)',
  XL = '(min-width: 1200px)',
  XXL = '(min-width: 1400px)',
}

export function getCssVariableValue(variableName: string) {
  if (cssVariableCache[variableName]) {
    return cssVariableCache[variableName];
  }

  const hex = getComputedStyle(document.documentElement).getPropertyValue(
    variableName,
  );

  if (hex && hex.length > 0) {
    return hex.trim();
  }

  cssVariableCache[variableName] = hex;
  return hex;
}

type GraphileResult<T, A extends keyof T> = {
  [key in A]: {
    nodes: any[];
    totalCount: number;
  } | null;
};

export function gqlDataSourceLoader<
  T extends GraphileResult<T, K>,
  K extends keyof T,
>(
  tableDataSource: DefaultDataTableSource<any, any, any>,
  router: Router,
  activatedRoute: ActivatedRoute,
  fetch: () => Observable<ApolloQueryResult<T>>,
  key: K,
) {
  tableDataSource.setQueryParams(activatedRoute.snapshot.queryParams);

  return merge(
    activatedRoute.queryParams,
    tableDataSource.reload$,
    tableDataSource.optionsChange$.pipe(
      filter(() => {
        router.navigate([], {
          relativeTo: activatedRoute,
          queryParams: tableDataSource.getQueryParams(),
          queryParamsHandling: 'merge',
          state: {
            animation: false,
          },
        });
        return false;
      }),
    ),
  ).pipe(
    tap(() => (tableDataSource.loading = true)),
    catchError(() => {
      tableDataSource.loading = false;
      return EMPTY;
    }),
    switchMap(fetch),
    tap(() => (tableDataSource.loading = false)),
    tap((result) => {
      tableDataSource.data = result.data[key]?.nodes ?? [];
      tableDataSource.count = result.data[key]?.totalCount ?? 0;
    }),
  );
}

export function gqlDataSourceLoader2<
  T extends GraphileResult<T, K>,
  K extends keyof T,
  V,
>(
  tableDataSource: DefaultDataTableSource<any, any, any>,
  router: Router,
  activatedRoute: ActivatedRoute,
  gql: any,
  options: () => V,
  key: any,
): Observable<any> {
  const watcher = gql.watch(options()) as QueryRef<any>;
  tableDataSource.setQueryParams(activatedRoute.snapshot.queryParams);
  return merge(
    activatedRoute.queryParams,
    tableDataSource.reload$,
    tableDataSource.optionsChange$.pipe(
      filter(() => {
        router.navigate([], {
          relativeTo: activatedRoute,
          queryParams: tableDataSource.getQueryParams(),
          queryParamsHandling: 'merge',
          state: {
            animation: false,
          },
        });
        return false;
      }),
    ),
  ).pipe(
    tap(() => watcher.refetch(options() as any)),
    tap(() => (tableDataSource.loading = true)),
    catchError(() => {
      tableDataSource.loading = false;
      return EMPTY;
    }),
    switchMap(() => watcher.valueChanges),
    tap(() => (tableDataSource.loading = false)),
    tap((result) => {
      tableDataSource.data = result.data[key]?.nodes ?? [];
      tableDataSource.count = result.data[key]?.totalCount ?? 0;
    }),
  );
}

export function buildLocalized(
  values: Record<string, any>,
  nodes?: { id: number; langId: number }[],
): {
  create: {
    lang: string;
    langId: number;
    id: number | undefined;
  }[];
  update: {
    lang: string;
    langId: number;
    id: number;
  }[];
} {
  const result = Object.keys(values).map((lang) => {
    const langId = langToLangId(lang);
    return {
      lang,
      langId,
      id: nodes?.find((lang) => lang.langId === langId)?.id,
    };
  });
  if (!nodes) {
    return {
      create: result,
      update: [],
    };
  }
  const update = result.filter(
    (entry): entry is { lang: string; langId: number; id: number } =>
      !!entry.id,
  );
  const create = result.filter((entry) => !entry.id);
  return {
    create,
    update,
  };
}

export function langIdToLang(lang: number) {
  switch (lang) {
    case 2:
      return 'lt';
    case 1:
      return 'en';
  }
  throw new Error('Unsupported language');
}

export function langToLangId(lang: string) {
  switch (lang) {
    case 'lt':
      return 2;
    case 'en':
      return 1;
  }
  return -1;
}

export function mergeFilters<T>(objects: T[]): T | undefined {
  return objects.length > 0 ? Object.assign({}, ...objects) : undefined;
}

export function convertLinks(input: string) {
  let text = input.replaceAll('\n', '<br>');
  const linksFound = text.match(/(?:www|https?)[^\s]+/g);
  const aLink: string[] = [];
  const httpRegex = /(http(s?)):\/\//;
  if (linksFound === null) {
    return text;
  }
  for (let i = 0; i < linksFound.length; i++) {
    const replace = linksFound[i];
    let linkText = replace;
    if (linkText.substring(0, 3) == 'www') {
      linkText = linkText.replace('www.', '');
    }
    if (linkText.match(/youtu/)) {
      const youtubeID = replace.split('/').slice(-1)[0];
      aLink.push(
        `<div class="video-wrapper">
          <iframe 
          width="560" 
          height="315"
          src="https://www.youtube.com/embed/${youtubeID}" 
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen></iframe>
        </div>`,
      );
    } else if (linkText.match(/vimeo/)) {
      const vimeoID = replace.split('/').slice(-1)[0];
      aLink.push(
        '<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' +
          vimeoID +
          '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>',
      );
    } else {
      aLink.push(
        '<a href="' + replace + '" target="_blank">' + linkText + '</a>',
      );
    }
    text = text
      .split(linksFound[i])
      .map((item) => {
        return aLink[i].includes('iframe') ? item.trim() : item;
      })
      .join(aLink[i]);
  }
  return text;
}
